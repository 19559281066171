import "../../../../styling/filters.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { ParentContext } from "../../../constants/ParentContext";

export default function MultipleTextFilter({
  filter,
  addedFilters,
  apply,
  hideApply,
  clear,
  inIndustry,
}) {
  // #region CONSTANTS & STATE VARIABLES
  const context = useContext(ParentContext);
  const isBiz = context.isBiz4Biz || context.isHarbour ? false : true;

  const [text, setText] = useState("");
  const [chosenOptions, setChosenOptions] = useState([]);
  const [focused, setFocused] = useState(false);
  const [updateVar, setUpdateVar] = useState(0);

  const gridRef = useRef();

  // #endregion
  useEffect(() => {
    setChosenOptions([]);

    addedFilters.forEach((element) => {
      if (element.id === filter.id) {
        console.log("HE", element);
        // setText(element.value.text)
        setChosenOptions(element.value.inputs);
      }
    });
  }, [addedFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    gridRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chosenOptions.length]);

  // #region SHOW COMPONENTS
  const showSelected = () => {
    let compArray = [];

    chosenOptions.forEach((element) => {
      compArray.push(
        <div className="appliedFilterValue spaced" key={element}>
          <span className="text-xs semibold gray-700 alignLeft oneLine">
            {element}
          </span>
          <img
            className="appliedFilterClose"
            src="/assets/x-close.png"
            onClick={() => remove(element)}
            alt="close"
          />
        </div>
      );
    });

    return compArray;
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED

  const remove = (name) => {
    var index = 0;
    chosenOptions.forEach((element) => {
      if (element === name) {
        chosenOptions.splice(index, 1);
      }
      index++;
    });
    setChosenOptions(chosenOptions);
    setUpdateVar(updateVar + 1);

    if (chosenOptions.length === 0) {
      clear();
    } else {
      apply(filter, { inputs: chosenOptions });
    }
  };

  const applyFilter = () => {
    apply(filter, { inputs: chosenOptions });
  };

  //   const applyFilter = () => {
  //     apply(filter,{names:chosenOptions,containChoice:containChoice})
  // }

  // #endregion

  // #region OTHER

  // #endregion

  // const isDisabled = () => {
  //   if(chosenOptions.length === 0)
  //   {
  //       return true
  //   }
  //   else{
  //       return false
  //   }
  // }

  const addTag = () => {
    // let tag = text;

    let tags = text.split(',')

    for(const tag of tags)
    {
      if (!chosenOptions.includes(tag)) {
        chosenOptions.push(tag);
      }
    }
   
    setChosenOptions(chosenOptions);

    if (inIndustry) {
      apply(filter, { inputs: chosenOptions });
    }
    setText("");
  };

  const isDisabled = () => {
    if (chosenOptions.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="textFilter padding3">
      <div
        className={`textInput ${
          focused ? "focus" : ""
        } search text-md regular gray-900`}
      >
        <div className="searchMultipleGrid">
          {showSelected()}

          <input
            ref={gridRef}
            className="textInput inner"
            type="text"
            placeholder={filter.placeholder}
            value={text}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={(e) => setText(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                addTag();
              }
            }}
          />
        </div>
      </div>

      {!inIndustry && (
        <button
          className="secondaryButton applyFilterBtn"
          disabled={isDisabled()}
          onClick={applyFilter}
        >
          <img
            className="secondaryButtonIcon"
            src={
              isDisabled()
                ? "/assets/grey-plus.png"
                : isBiz
                ? "/assets/red-plus.png"
                : "/assets/blue-plus.png"
            }
            alt="plus"
          />
          <span
            className={`text-xs semibold ${
              isDisabled() ? "gray-300" : "primary-700"
            }`}
          >
            Apply filter
          </span>
        </button>
      )}

      {/* {!hideApply && <button className='secondaryButton applyFilterBtn' onClick={applyFilter}>
                <img className='secondaryButtonIcon' src={isDisabled()?'/assets/grey-plus.png':isBiz?'/assets/red-plus.png':'/assets/blue-plus.png'}/>
                <span className={`text-xs semibold ${isDisabled()?'gray-300':'primary-700'}`}>Apply filter</span>
            </button>} */}
    </div>
  );
}
