import '../../styling/saved.css'
import React, { useState } from 'react';
import axios from "axios";

import { auth } from '../..';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import PaymentComponent from '../PaymentComponent';

export default function BuyCreditsPopUp({close,editPayment,paymentMethod,complete,option}) {

    const live = window.location.hostname.includes('app.bizcrunch.co')

    const [loading, setLoading] = useState(false)

    const [intentID, setIntentID] = useState("")
    const [options, setOptions] = useState(null)
    const [submitCount, setSubmitCount] = useState(0)

    
const appearance = {
    variables:{
    
    }
    
};
    
    const baseURL = process.env.REACT_APP_BASEURL;
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    // const localURL = process.env.REACT_APP_LOCALURL;
    
    const provider = paymentMethod?paymentMethod.card.networks.available[0].charAt(0).toUpperCase() + paymentMethod.card.networks.available[0].slice(1):"";
    const providerImage = paymentMethod?provider==='Visa'?'/assets/visa.png':'/assets/mastercard.png':""
    let stripeTest
    let stripeLive
    if (process.env.REACT_APP_STRIPE_TEST) {
        stripeTest=loadStripe(process.env.REACT_APP_STRIPE_TEST);
    }
    if (process.env.REACT_APP_STRIPE_LIVE) {
        stripeLive=loadStripe(process.env.REACT_APP_STRIPE_LIVE);
    }
    // const stripeTest = loadStripe(process.env.REACT_APP_STRIPE_TEST);
    // const stripeLive = loadStripe(process.env.REACT_APP_STRIPE_LIVE);
    

    const cancelClicked = () => {
        close()
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));


    const createPaymentIntent = () => {
        setLoading(true);
        let user = JSON.parse(localStorage.getItem("user"))
        let cusID = user.stripeCustomerID
        axios.get(baseURL+'createPaymentIntent',{
            params:{
                amount:Math.round(option.credits*option.rate*100)/100,
                customer: cusID,
            }
            
            
        }).then( async (response) => {

            if(response.data.error)
            {

            }
            else{
                let intent = response.data.paymentIntent.client_secret

                setOptions({clientSecret:intent,appearance:appearance})
                setIntentID(intent)

                await delay(1000)
                console.log("UEF 2")

                setSubmitCount(submitCount+1)
                // setSubmitCount(submitCount+1)
            }
        });
    }


    const stripeResponse = (response) => {

        console.log("STRIPRE RESPONSE",response)
    
        if(response.success){
            complete(option.credits)
            addCreditsToAccount()
        }
    }

    const addCreditsToAccount = () => {
        setLoading(true)
        axios.post(baseURL2+'addCreditsToAccount',{
            uid:auth.currentUser.uid,
            amount:option.credits
    
        }).then((response) => {
            
            setLoading(false)
        })
    }

    

    return(
        
        <div className='tint'>
            <div className='popUp confirmPlan'>
                <div className='row jc-between width100 ai-centre'>
                    <span className='text-lg semibold gray-900'>Buy Export credit</span>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>


                {/* {!amountP && <div className='sfpuMain inner'>
                    <span className='text-sm medium gray-700'>How many credits do you want to purchase?</span>
                    <input className={`textInput text-md gray-900`} type="number" placeholder={"Enter file name"} value={amount} onChange={(e) => setAmount(e.target.value)} disabled={loading}/>
                </div>} */}
                

                <div className='column width100 gap12'>
                    <div className='row jc-between width100'>
                        <span className='text-md semibold gray-600'>{`${option.credits} x £${option.rate}`}</span>
                        <span className='text-md regular gray-600'>{`£${(option.credits*option.rate).toFixed(2)}`}</span>
                    </div>
                    <div className='row jc-between width100'>
                        <span className='text-md regular gray-600'>VAT</span>
                        <span className='text-md regular gray-600'>{`£${(option.credits*option.rate*.2).toFixed(2)}`}</span>
                    </div>

                    <div className='greyLine'/>

                    <div className='row jc-between width100'>
                        <span className='text-md semibold gray-600'>Total</span>
                        <span className='text-md semibold gray-600'>{`£${(option.credits*option.rate+(option.credits*option.rate*.2)).toFixed(2)}`}</span>
                    </div>

                    <div className='greyLine'/>

                </div>

                <div className='confirmPayment'>
                    <div className='cptCardProviderImg'>
                        {paymentMethod && <img src={providerImage} alt='cardProvider'/>}
                    </div>
                    <div className='cptCardDetails'>
                        {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
                        {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
                        {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
                        <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
                    </div>
                </div>

                {intentID && options &&
                    <Elements stripe={live?stripeLive:stripeTest} options={options} >
                        <PaymentComponent stripeResponse={stripeResponse} submitCount={submitCount} payment={true} intentID={intentID} savedCard={paymentMethod.id}/>
                    </Elements>
                }

                {loading && <div className='loader'>
                    <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    </div>
                }

                {!loading && <div className='row width100 jc-centre gap20'>
                    <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
                    <button className='primaryButton width50' onClick={createPaymentIntent}>Buy Now</button>
                </div>}
                
            </div>            
        </div>
        

    )

}