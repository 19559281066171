
import '../../styling/saved.css'
import React, { useEffect,useState } from 'react';


export default function CreditBreakdownPopUp({selectedBusinesses}) {


    const [standards,setStandards] = useState(0)
    const [premiums,setPremiums] = useState(0)
    const [emails,setEmails] = useState(0)
    const [overallCredits,setOverallCredits] = useState(0)


        
    useEffect(() => {
        
        var standards = 0
        var premiums = 0
        var credits = 0
        var emails = 0

        selectedBusinesses.forEach(element => {
            console.log("ELESS",element)
            if(element.premium){
                premiums+=1
                credits+=10
            }else{
                standards+=1
                credits+=1
            }
            if(element.emailCount>0)
            {
                emails++;
                credits+=3;
            }
        });

        setStandards(standards)
        setPremiums(premiums)
        setEmails(emails)
        setOverallCredits(credits)

    },[selectedBusinesses])


    

    

    return(


        <div className='popUp creditBreakdown text-sm regular gray-600'>
            
            <div className='width100 row jc-between'>
                <span>{standards} Standard companies</span>
                <span>{standards}</span>
            </div>
            <div className='width100 row jc-between'>
                <span>{premiums} Premium companies</span>
                <span>{premiums*10}</span>
            </div>
            <div className='width100 row jc-between'>
                <span>{emails} Emails</span>
                <span>{emails*3}</span>
            </div>

            <div className='greyLine'/>

            <div className='width100 row jc-between gray-700 semibold'>
                <span>Credit costs</span>
                <span>{overallCredits}</span>
            </div>
            
        </div>            


    )

}