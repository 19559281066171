
import '../styling/login.css'
import '../styling/constants/button.css'
import '../styling/constants/inputs.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";


export default function FundMyDeal() {


    const [content, setContent] = useState(null);
    // const [error, setError] = useState(null);


    const [done, setDone] = useState(false);


    const baseURL2 = process.env.REACT_APP_LOCALURL2;


    useEffect(() => {
        axios.get(baseURL2+'caolanTest',{
            
        }).then( (response) => {
        
            setContent(response.data.html);
            
        }).catch((err) => {
            // setError("Error fetching content");
        });

    },[])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setDone(true)
        if (content && !done) {
            const scripts = document.querySelectorAll("script");
            scripts.forEach(script => {
                try {
                    const newScript = document.createElement("script");
    
                    if (script.src) {
                        newScript.src = script.src;
                    } else {
                        newScript.textContent = script.textContent;
                    }
    
                    document.body.appendChild(newScript);
                } catch (err) {
                    console.error("Error injecting script: ", err);
                }
            });
        }
    }, [content]);// eslint-disable-line react-hooks/exhaustive-deps

    

    return(
        <div className="column marginTop200 ai-centre height100">
            
{/*         
            <iframe title='fundingIframe' className='fundIframe' src="https://bizcrunch.swoopfunding.com" ></iframe>
           

           <object data="https://bizcrunch.swoopfunding.com" width="600" height="400">
                
                Error: Embedded data could not be displayed.
            </object>

            <embed src="https://bizcrunch.swoopfunding.com" width="600" height="400"/> */}


            <div>
            {content ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                    <div>Loading...</div>
                )}
            </div>

            
        </div>
    )

}