import '../../styling/saved.css'
import React, { useEffect } from 'react';


export default function LoadingPopUp() {

   
    useEffect(() => {
        
    },[])


  
    


    

    return(

        <div className='tint'>
            <div className='popUp loading'>
                {/* <div className='row jc-end width100'>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close}/>
                </div> */}

                


                <lottie-player className="loader" src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="white" speed="1" style={{transform: 'scale(1.7)'}} loop autoplay></lottie-player>
                
                
            </div>            
        </div>

    )

}