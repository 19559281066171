
import '../../styling/pricing.css'
import axios from "axios";

import React, { useEffect, useState } from 'react';
import Tooltip from '../constants/Tooltip';
import { auth } from '../..';
import IFramePopUp from '../popUps/iframePopUp';



export default function PricingIFrame({userP}) {

    const baseURL2 = process.env.REACT_APP_BASEURL2;


    const [toggle, setToggle] = useState("monthly")
    const [sliderChoice,setSliderChoice] = useState(1)
    const [selectedPlan, setSelectedPlan] = useState(0)

    const [monthPlans, setMonthPlans] = useState([])
    const [yearPlans, setYearPlans] = useState([])

    const [showPopUp, setShowPopUp] = useState(false)

    const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600')

    useEffect(() => {
        if(auth.currentUser){
            getPlanData()        
        }
    },[auth.currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

        
    useEffect(() => {

        if(monthPlans.length>0 && yearPlans.length>0)
        {
            if(toggle === "monthly"){
                setSelectedPlan(monthPlans[sliderChoice-1])
            }else{
                setSelectedPlan(yearPlans[sliderChoice-1])
            }
        }
        
        

    },[sliderChoice,toggle,monthPlans.length,yearPlans.length]) // eslint-disable-line react-hooks/exhaustive-deps
        



    const getPlanData = (plan) => {

        axios.get(baseURL2+'getPlanDataV2',{
            params:{
                uid:auth.currentUser.uid
            }
        }).then( (response) => {
        
            let plans = response.data.plan
    
            let monthArray = []
            let yearArray = []


            plans.forEach(plan => {
                if(plan.id!=="free")
                {
                    if(plan.Frequency === "monthly")
                    {
                        monthArray.push(plan)
                    }   
                    else{
                        yearArray.push(plan)
                    }
                }            
            });

            let mPlans = monthArray.sort((a, b) => {return a.Rate - b.Rate;})
            let yPlans = yearArray.sort((a, b) => {return a.Rate - b.Rate;})

            mPlans.shift()
            yPlans.shift()

            setMonthPlans(mPlans)
            setYearPlans(yPlans)
    
        });
      }


    

    const calculatePrice = () => {
        if(toggle === "monthly")
        {
            switch(+sliderChoice){
                // case 1: return 50;
                case 1: return 90;
                case 2: return 160;
                case 3: return 280;
                default: return 90;
            }
        }else{
            switch(+sliderChoice){
                // case 1: return 540;
                case 1: return 972;
                case 2: return 1728;
                case 3: return 3024;
                default: return 972;
            }
        }
        
    }
    const calculateCredits = () => {
        
        var multiplier = toggle==="monthly"?1:12

        switch(+sliderChoice){
            // case 1: return 500*multiplier;
            case 1: return 1000*multiplier;
            case 2: return 2000*multiplier;
            case 3: return 4000*multiplier;
            default: return 1000*multiplier;
        }
    
    }

    const purchasePlan = () => {
        console.log("SELECTED PLAN",selectedPlan)
        setShowPopUp(true)
    }

    const openArticle = () => {
        let url = "https://bizcrunch.tawk.help/article/how-do-bizcrunch-credits-work"
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const register = () => {
        let url = "https://app.bizcrunch.co/login"
        window.open(url, '_blank', 'noopener,noreferrer');
    }
    
    const successfulPayment = () => {
        
    }


    return(
    <div className='column gap57 ai-centre marginTop20'>

        <span className='gray-700 semibold display-xl'>Pricing</span>

        <div className='pricingToggle'>
            <div className={`pricingToggleOption ${toggle==="monthly"?"selected":""}`} onClick={()=>setToggle("monthly")}>
                <span className='text-sm'>Monthly</span>
            </div>
            <div className={`pricingToggleOption ${toggle==="yearly"?"selected":""}`} onClick={()=>setToggle("yearly")}>
                <span className='text-sm'>Annually</span>
                <div className='pricingToggleDiscount row jc-centre ai-centre text-xs bold'>Save 10%</div>
            </div>
        </div>

        <div className='column ai-centre gap32 z200 pricingTile padding24'>
             
             

             


             <div className='row'>
                <div className='column ai-start width50 gap40 padding24'>
                    <div className='column ai-start gap8'>
                        <span className='gray-700 bold display-xs'>Export credits</span>
                        <span className='text-md regular'>Choose the number of export credits</span>
                    </div>

                    <div className='column gap8'>


                        <input type="range" min="1" max="3" value={sliderChoice} className="pricingSlider" id="myRange" onChange={(e)=>setSliderChoice(e.target.value)}
                        style={{'background': `linear-gradient(to right, ${primary600} 0%, ${primary600} ${(50*(sliderChoice-1))}%, #F2F4F7 ${(50*(sliderChoice-1))}%, #F2F4F7 100%)`}}/>

                        
                        <div className='row width100 jc-between'>
                            {/* <div className='column gap4 ai-end'>
                                <span className='regular text-md'>500</span>
                            </div> */}
                            <div className='column gap4 ai-end'>
                                <span className='regular text-md'>1000</span>
                                {/* <span className='primary-700 bold text-xs'>Save 10%</span> */}
                            </div>
                            <div className='column gap4 ai-end'>
                                <span className='regular text-md'>2000</span>
                                <span className='primary-700 bold text-xs'>Save 20%</span>
                            </div>
                            <div className='column gap4 ai-end'>
                                <span className='regular text-md'>4000</span>
                                <span className='primary-700 bold text-xs'>Save 30%</span>
                            </div>
                        </div>
                        
                    </div>

                    <div className='column ai-start gap16'>
                        <div className='row ai-centre gap4'>
                            <span className='gray-700 bold display-xs'>£{calculatePrice()}</span>
                            <span className='gray-400 medium text-lg'>+VAT</span>
                        </div>
                        <div className='row ai-centre gap8'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.9987 11.3333C7.9987 13.1743 9.49108 14.6667 11.332 14.6667C13.173 14.6667 14.6654 13.1743 14.6654 11.3333C14.6654 9.49238 13.173 8 11.332 8C9.49108 8 7.9987 9.49238 7.9987 11.3333ZM7.9987 11.3333C7.9987 10.5828 8.24676 9.89017 8.66536 9.33301V3.33333M7.9987 11.3333C7.9987 11.8836 8.13202 12.4027 8.36812 12.8601C7.80648 13.3345 6.50926 13.6667 4.9987 13.6667C2.97365 13.6667 1.33203 13.0697 1.33203 12.3333V3.33333M8.66536 3.33333C8.66536 4.06971 7.02374 4.66667 4.9987 4.66667C2.97365 4.66667 1.33203 4.06971 1.33203 3.33333M8.66536 3.33333C8.66536 2.59695 7.02374 2 4.9987 2C2.97365 2 1.33203 2.59695 1.33203 3.33333M1.33203 9.33333C1.33203 10.0697 2.97365 10.6667 4.9987 10.6667C6.45803 10.6667 7.71825 10.3567 8.30846 9.90785M8.66536 6.33333C8.66536 7.06971 7.02374 7.66667 4.9987 7.66667C2.97365 7.66667 1.33203 7.06971 1.33203 6.33333" stroke="#98A2B3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className='gray-700 text-sm medium'>{calculateCredits()} Export Credits / {toggle==="monthly"?"month":"year"}</span>
                        </div>
                    </div>


                    <div className='column ai-centre gap12 width100'>
                        <button className='primaryButton width100 row jc-centre ai-centre gap8' onClick={()=>purchasePlan(selectedPlan)}>
                            <span className='medium text-md'>Purchase Plan</span>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.82422 8.09961H13.1751" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.5 3.42578L13.1754 8.09828L8.5 12.7708" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <span className='gray-700 regular text-sm'>or</span>
                        <button className='secondaryButton width100 row ai-centre gap8' onClick={register}>
                            <span className='primary-700 medium text-md'>Get started for free with</span>
                            <span className='gray-700 medium text-sm'>25 Credits</span>
                        </button>
                    </div>


                </div>

                <div className='column ai-start width50 lightGrey padding50 gap32 br32'>
                    <div className='width100 column gap24'>
                        <div className='width100 row jc-between ai-centre'>
                            <span className='gray-700 medium text-md'>Credit cost breakdown</span>
                            <span className='primary-700 medium text-xs pointer' onClick={openArticle}>About credits</span>
                        </div>

                        <div className='width100 column gap16'>
                            <div className='width100 row ai-centre jc-between'>
                                <div className='row ai-centre gap12'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.66536 7.33333H11.8654C12.6121 7.33333 12.9855 7.33333 13.2707 7.47866C13.5216 7.60649 13.7255 7.81046 13.8534 8.06135C13.9987 8.34656 13.9987 8.71993 13.9987 9.46667V14M8.66536 14V4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203M4.33203 10H6.33203" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                    <div className='row ai-centre gap8'>
                                        <span className='gray-700 regular text-sm'>Export Standard Company</span>
                                        {/* <Tooltip icon={'normal'} text={"Standard company"}/> */}
                                    </div>

                                </div>
                                <span className='gray-700 medium text-sm'>1 credits</span>
                            </div>

                            <div className='width100 row ai-centre jc-between'>
                                <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.9987 11.4667V14M8.66536 4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.75 11V9.42105M8 10.2105H9.5M12.3 4L11.7797 5.42385C11.6951 5.6554 11.6528 5.77117 11.5871 5.86855C11.5288 5.95486 11.4571 6.03027 11.3751 6.09164C11.2826 6.16089 11.1726 6.20541 10.9527 6.29447L9.6 6.84211L10.9527 7.38974C11.1726 7.4788 11.2826 7.52332 11.3751 7.59257C11.4571 7.65394 11.5288 7.72935 11.5871 7.81566C11.6528 7.91304 11.6951 8.02881 11.7797 8.26036L12.3 9.68421L12.8203 8.26036C12.9049 8.02881 12.9472 7.91304 13.0129 7.81566C13.0712 7.72935 13.1429 7.65394 13.2249 7.59257C13.3174 7.52332 13.4274 7.4788 13.6473 7.38974L15 6.84211L13.6473 6.29447C13.4274 6.20541 13.3174 6.16089 13.2249 6.09164C13.1429 6.03027 13.0712 5.95486 13.0129 5.86855C12.9472 5.77117 12.9049 5.6554 12.8203 5.42385L12.3 4Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                    <div className='row ai-centre gap8'>
                                        <span className='gray-700 regular text-sm'>Export Premium Company</span>
                                        <Tooltip icon={'normal'} text={"Premium companies are the very best prospects in the UK. Click ‘About Credits’ above to learn more"}/>
                                    </div>

                                </div>
                                <span className='gray-700 medium text-sm'>10 credits</span>
                            </div>

                            <div className='width100 row ai-centre jc-between'>
                                <div className='row ai-centre gap12'>
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.67177 3H13.3584C14.0931 3 14.6943 3.675 14.6943 4.5V13.5C14.6943 14.325 14.0931 15 13.3584 15H2.67177C1.93706 15 1.33594 14.325 1.33594 13.5V4.5C1.33594 3.675 1.93706 3 2.67177 3Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.6943 4.5L8.0151 9.75L1.33594 4.5" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                    <div className='row ai-centre gap8'>
                                        <span className='gray-700 regular text-sm'>Export Owner Email Address</span>
                                        <Tooltip icon={'normal'} text={"Get the direct email address of a company owner. Click ‘About Credits’ above to learn more"}/>
                                    </div>

                                </div>
                                <span className='gray-700 medium text-sm'>3 credits</span>
                            </div>

                            {/* <div className='width100 row ai-centre jc-between'>
                                <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1606_5365)">
                                    <path d="M14 6.66602C14 11.3327 8 15.3327 8 15.3327C8 15.3327 2 11.3327 2 6.66602C2 5.07472 2.63214 3.54859 3.75736 2.42337C4.88258 1.29816 6.4087 0.666016 8 0.666016C9.5913 0.666016 11.1174 1.29816 12.2426 2.42337C13.3679 3.54859 14 5.07472 14 6.66602Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 8.66602C9.10457 8.66602 10 7.77059 10 6.66602C10 5.56145 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 5.56145 6 6.66602C6 7.77059 6.89543 8.66602 8 8.66602Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1606_5365">
                                    <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>

                                    <div className='row ai-centre gap8'>
                                        <span className='gray-700 regular text-sm'>Export Trading Address</span>
                                        <Tooltip icon={'normal'} text={"Standard company"}/>
                                    </div>

                                </div>
                                <span className='gray-700 medium text-sm'>1 credits</span>
                            </div>

                            <div className='width100 row ai-centre jc-between'>
                                <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.7 5.2C1.7 4.45405 1.70048 3.93423 1.7316 3.52958C1.76218 3.13193 1.81918 2.90543 1.90045 2.73596C2.07596 2.36998 2.3521 2.0804 2.68222 1.90169C2.82771 1.82293 3.02562 1.76484 3.3908 1.73314C3.76549 1.70061 4.24885 1.7 4.95294 1.7H11.0471C11.7512 1.7 12.2345 1.70061 12.6092 1.73314C12.9744 1.76484 13.1723 1.82293 13.3178 1.90169C13.6479 2.08041 13.924 2.36998 14.0995 2.73596C14.1808 2.90543 14.2378 3.13193 14.2684 3.52958C14.2995 3.93423 14.3 4.45405 14.3 5.2V10.8C14.3 11.5459 14.2995 12.0658 14.2684 12.4704C14.2378 12.8681 14.1808 13.0946 14.0995 13.264C13.924 13.63 13.6479 13.9196 13.3178 14.0983C13.1723 14.1771 12.9744 14.2352 12.6092 14.2669C12.2345 14.2994 11.7512 14.3 11.0471 14.3H4.95294C4.24885 14.3 3.76549 14.2994 3.3908 14.2669C3.02562 14.2352 2.8277 14.1771 2.68222 14.0983C2.3521 13.9196 2.07596 13.63 1.90045 13.264C1.81918 13.0946 1.76218 12.8681 1.7316 12.4704C1.70048 12.0658 1.7 11.5459 1.7 10.8V5.2Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.9993 12H10.3429V9.40146C10.3429 8.78186 10.3319 7.98418 9.48143 7.98418C8.6187 7.98418 8.48673 8.65931 8.48673 9.35638V11.9998H6.83033V6.65623H8.42047V7.38649H8.44271C8.60187 7.11392 8.83183 6.8897 9.1081 6.7377C9.38437 6.58569 9.69662 6.5116 10.0116 6.52332C11.6905 6.52332 12 7.62949 12 9.06858L11.9993 12ZM4.96139 5.9258C4.77128 5.92584 4.58542 5.8694 4.42733 5.76362C4.26924 5.65785 4.14602 5.50748 4.07324 5.33155C4.00046 5.15561 3.98138 4.96201 4.01843 4.77522C4.05549 4.58843 4.14701 4.41684 4.28141 4.28215C4.41582 4.14746 4.58707 4.05572 4.77353 4.01854C4.95998 3.98135 5.15325 4.00038 5.3289 4.07323C5.50455 4.14608 5.6547 4.26947 5.76034 4.4278C5.86599 4.58613 5.9224 4.77228 5.92244 4.96273C5.92246 5.08918 5.89761 5.2144 5.84933 5.33123C5.80105 5.44806 5.73027 5.55422 5.64103 5.64365C5.55179 5.73308 5.44583 5.80403 5.32922 5.85244C5.2126 5.90085 5.08762 5.92578 4.96139 5.9258ZM5.78958 12H4.13147V6.65623H5.78958V12Z" fill={primary600}/>
                                </svg>

                                    <div className='row ai-centre gap8'>
                                        <span className='gray-700 regular text-sm'>Individual LinkedIn</span>
                                        <Tooltip icon={'normal'} text={"Standard company"}/>
                                    </div>

                                </div>
                                <span className='gray-700 medium text-sm'>10 credits</span>
                            </div> */}

                            

                        </div>

                    </div>


                    <div className='width100 pricingExample'>
                        <span className='gray-700 text-xs regular'><strong>Example: </strong>Exporting 50 companies, including 10 premium companies, each with an email address, will cost 290 credits.</span>
                    </div>

                    <div className='column ai-start gap20'>
                        <span className='gray-700 medium text-md'>All plans include</span>
                        <div className='column ai-start gap12'>
                            <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='gray-700 regular text-sm'>Profit & Revenue Estimations</span>
                            </div>
                            <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='gray-700 regular text-sm'>Mail Merge Ready Exports</span>
                            </div>
                            <div className='row ai-centre gap12'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className='gray-700 regular text-sm'>Smart FIltering & Advanced Insights</span>
                            </div>
                        </div>
                    </div>

                </div>
             </div>
             
        </div>

        {showPopUp && <IFramePopUp userP={userP} newPlan={selectedPlan} closeP={()=>setShowPopUp(false)} success={successfulPayment}/>}
        </div>
    )

    
}