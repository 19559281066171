import "../../../../styling/search.css";
import React, { useState } from "react";

import SICFilter from "../../Advanced/FilterTypes/SICFilter";
// import AIIcon from "../../../constants/AiIcon";
// import MultipleTextFilter from "../../Advanced/FilterTypes/MultipleTextFilter";

export default function IndustrySearch({
  appliedFilters,
  applyFilter,
  remove,
  done,
}) {
  // #region CONSTANTS & STATE VARIABLES
  const [searchType] = useState("sic");
  // const [searchType, setSearchType] = useState("sic");

  // #endregion

  // #region SHOW COMPONENTS

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED

  // #endregion

  // #region OTHER

  // #endregion

  const clear = () => {
    remove(filter);
    // remove(filter2);
    // remove(filter3);

  };

  let filter = {
    id: "2C",
    title: "SIC Industry name",
    type: "multiple",
    subType: "sic",
    placeholder: "Enter SIC Code",
  };

  // let filter2 = {
  //   id: "2C2",
  //   title: "ISIC Industry name",
  //   type: "multiple",
  //   subType: "sic",
  //   placeholder: "Enter ISIC Code",
  // };

  // let filter3 = {id:"2I",title:"KeyWord",type:"multiple", placeholder:"Keywords",subType:"text"};

  return (
    <div className="searchBubble industry !overflow-y-visible">
      <div className="searchBubbleTitleRow">
        {/* <span className="text-sm medium gray-700">Industry & Keywords</span> */}
        <span className="text-sm medium gray-700">Industry</span>
        <span className="text-sm medium primary-700 pointer" onClick={clear}>
          Clear
        </span>
      </div>

      {/* <div className="row gap20 ai-centre">
        <div className="radioRow">
          <input
            className="radio input-sm"
            type="radio"
            checked={searchType === "sic"}
            onChange={() => setSearchType("sic")}
          />
          <span className="text-sm medium gray-700">{"SIC & ISIC Code"}</span>
        </div>
        <div className="radioRow">
          <input
            className="radio input-sm"
            type="radio"
            checked={searchType === "ai"}
            onChange={() => setSearchType("ai")}
          />
          <AIIcon />
          <span
            className="text-sm medium"
            style={{
              background: `var(--bizcrunch-gradient)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {"AI Company Search"}
          </span>
        </div>
      </div> */}

      {searchType==="sic" && <SICFilter
        filter={filter}
        addedFilters={appliedFilters}
        apply={applyFilter}
        clear={()=>remove(filter)}
      />}

      {/* {searchType==="sic" && <SICFilter
        filter={filter2}
        addedFilters={appliedFilters}
        apply={applyFilter}
        clear={()=>remove(filter2)}
        isic={true}
      />}

      {searchType==="ai" && <MultipleTextFilter 
      
        filter={filter3}
        addedFilters={appliedFilters}
        apply={applyFilter}
        clear={()=>remove(filter3)}
        inIndustry={true}
      />} */}



      <div className="loginForgotRow margin24">
        <button onClick={done} className="text-sm medium gray-700">
          Done
        </button>
      </div>
    </div>
  );
}
