import { useState } from "react";
import { auth } from "../..";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function UnpausePlanPopUp({ close, subscription, pausedUntil }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const unpausePlan = () => {
    setLoading(true);
    axios
      .post(
        baseURL2 + "unpauseSubscription",
        {},
        {
          params: {
            uid: auth.currentUser.uid,
            subscriptionID: subscription.id,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        navigate(0);
        close();
      });
  };
  return (
    <div className="tint">
      <div className="popUp unpausePlan text-left">
        <div className="row jc-end width100">
          <img
            className="closeButton pointer"
            src="/assets/x-close.png"
            onClick={close}
            alt="close"
          />
        </div>
        <div className="sfpuMain inner3">
          <span className="text-lg semibold gray-900">
            Are you sure you want to resume your subscription?
          </span>
          <span className="text-sm regular gray-600">
            Your subscription is currently paused until{" "}
            {new Date(pausedUntil).toDateString()}.
          </span>
        </div>
        {!loading && (
          <div className="row width100 jc-between gap12 column">
            <button
              className="primaryButton width100"
              onClick={unpausePlan}
              disabled={loading}
            >
              Resume my subscription
            </button>
            <div className="tooltip width100">
              <button
                className="secondaryButton width100"
                onClick={close}
                disabled={loading}
              >
                Keep my subscription paused
              </button>
              {/* <span className="tooltiptext button text-sm regular">Coming soon</span> */}
            </div>
          </div>
        )}
        {loading && (
          <div className="loader">
            <lottie-player
              src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json"
              background="transparent"
              speed="1"
              style={{ transform: "scale(0.7)" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        )}
      </div>
    </div>
  );
}
