import "../styling/saved.css";
import "../styling/constants/divs.css";
import React, { useEffect, useState } from "react";
import axios from "axios";

import PaymentMethodTile from "../components/Billing/PaymentMethodTile";
import CurrentPlanTile from "../components/Billing/CurrentPlanTile";
import UpdatePaymentPopUp from "../components/Billing/UpdatePaymentPopUp";
import EditPlanPopUp from "../components/Billing/EditPlanPopUp";
// import ChoosePlanPopUp from '../components/Billing/ChoosePlanPopUp';
import CancelPlanPopUp from "../components/Billing/CancelPlanPopUp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Moment from "moment";
import ConfirmPlanPopUp from "../components/Billing/ConfirmPlanPopUp";
import BuyCreditsPopUpNew from "../components/Pricing/BuyCreditsPopUp";
import { auth } from "..";
import PricingPlanSelect from "../components/Pricing/PricingPlanSelect";
import PausePlanPopUp from "../components/Billing/PausePlanPopUp";
import UnpausePlanPopUp from "../components/Billing/UnpausePlanPopUp";

export default function Billing({ updateCredits, setUserP, saveToast, userP }) {
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  // #region CONSTANTS & STATE VARIABLES

  const [updateVar, setUpdateVar] = useState(0);

  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [showConfirmPlan, setShowConfirmPlan] = useState(false);
  const [showBuyCredits, setShowBuyCredits] = useState(false);
  const [showPausePlan, setShowPausePlan] = useState(false);
  const [showUnpausePlan, setShowUnpausePlan] = useState(false);

  const [user, setUser] = useState(null);

  const [plan, setPlan] = useState(null);
  const [plans, setPlans] = useState(null);

  const [customer, setCustomer] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [newPlan, setNewPlan] = useState(null);
  const [addingCredit, setAddingCredit] = useState(null);

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // #endregion

  // #region SHOW COMPONENTS

  const getTableRows = () => {
    var compArray = [];

    var index = 0;

    if (loading) {
      while (index < 10) {
        compArray.push(
          <tbody key={index}>
            <tr className="tableRow text-sm regular gray-600 pointer">
              <td className="width100">
                <div className="row ai-centre gap8">
                  <Skeleton height={20} width={20} />
                  <Skeleton width={200} />
                </div>
              </td>
              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td>
                <Skeleton width={60} borderRadius={12} />
              </td>

              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td className="text-sm semibold primary-700">
                <Skeleton width={90} />
              </td>
            </tr>
          </tbody>
        );
        index++;
      }
    } else {
      if (invoices !== null) {
        invoices.forEach((invoice) => {
          if (invoice.status !== "draft") {
            compArray.push(
              <tbody key={index}>
                <tr className="tableRow text-sm regular gray-600 pointer">
                  <td className="width100">
                    <div className="row ai-centre">
                      <input
                        id={`checkbox_id${index}`}
                        className="checkbox billing"
                        type="checkbox"
                        checked={selectedInvoices.includes(invoice.invoice_pdf)}
                        onChange={(e) =>
                          checkBoxClicked(invoice.invoice_pdf, e.target.checked)
                        }
                      />
                      <label
                        htmlFor={`checkbox_id${index}`}
                        className="checkboxLabel"
                      />
                      <span className="text-sm medium gray-900">{`Invoice #${
                        invoice.number || "TBD"
                      } - ${Moment(
                        new Date(invoice.period_start * 1000)
                      ).format("MMM YYYY")}`}</span>
                    </div>
                  </td>
                  <td className="text-sm regular gray-600">
                    {`${Moment(new Date(invoice.period_start * 1000)).format(
                      "MMM DD, YYYY"
                    )}`}
                  </td>

                  <td>
                    {invoice.paid && (
                      <div className="billingRowStatus">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10 3L4.5 8.5L2 6"
                            stroke="#17B26A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs medium success-700">Paid</span>
                      </div>
                    )}
                  </td>

                  <td className="text-sm regular gray-600">
                    {`£${invoice.total / 100}`}
                  </td>

                  <td className="text-sm regular gray-600">Basic plan</td>

                  <td className="text-sm semibold primary-700">
                    <a href={invoice.invoice_pdf}>Download</a>
                  </td>
                </tr>
              </tbody>
            );
          }
          index++;
        });
      } else {
      }
    }

    return compArray;
  };

  const checkBoxClicked = (url, checked) => {
    if (url === "all") {
      let urlArray = [];
      if (checked) {
        invoices.forEach((invoice) => {
          urlArray.push(invoice.invoice_pdf);
        });
      }
      setSelectedInvoices(urlArray);
    } else {
      let urlArray = selectedInvoices;
      if (checked) {
        urlArray.push(url);
        setSelectedInvoices(urlArray);
      } else {
        urlArray.splice(urlArray.indexOf(url), 1);
        setSelectedInvoices(urlArray);
      }
    }

    setUpdateVar(updateVar + 1);
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  //  const openCancelPlan = () => {
  //     setShowEditPlan(false)
  //     setShowCancelPlan(true)
  //  }
  const openPausePlan = () => {
    setShowEditPlan(false);
    setShowPausePlan(true);
  };
  const openChoosePlan = () => {
    setShowEditPlan(false);
    setShowChoosePlan(true);
  };
  // #endregion

  // #region OTHER

  // #endregion

  useEffect(() => {
    // let user = JSON.parse(localStorage.getItem("user"))
    // console.log("user",user)

    if (userP) {
      //   console.log("USER P", userP);
      let user = userP;
      let stripeCustomerID = user.stripeCustomerID;
      getCustomerData(stripeCustomerID);
      getPlanData(user.plan);
      setUser(user);
    }
  }, [userP]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showBuyCredits && !addingCredit) {
      setAddingCredit(true);
    }
  }, [showBuyCredits]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerData = (cusID) => {
    axios
      .get(baseURL2 + "getCustomersStripeData", {
        params: {
          stripeID: cusID,
        },
      })
      .then((response) => {
        setLoading(false);
        setCustomer(response.data.customer);
        setInvoices(response.data.invoices.data);
        setPaymentMethod(response.data.paymentMethod);
        localStorage.setItem(
          "paymentMethod",
          JSON.stringify(response.data.paymentMethod)
        );
        // console.log("SAVING PM", response.data.paymentMethod);
        setSubscription(response.data.subscription || null);

        let buyCredits = localStorage.getItem("buyCredits");

        if (buyCredits === "true") {
          setShowBuyCredits(true);
          localStorage.setItem("buyCredits", false);
        }
      });
  };

  const getPlanData = (plan) => {
    axios
      .get(baseURL2 + "getPlanDataV2", {
        params: {
          planID: plan,
          uid: auth.currentUser.uid,
        },
      })
      .then((response) => {
        let plans = response.data.plan;

        setLoading2(false);
        setPlans(plans);

        plans.forEach((planVar) => {
          if (planVar.id === plan) {
            setPlan(planVar);
          }
        });

        let upgradePlan = localStorage.getItem("upgradePlan");

        if (upgradePlan === "true") {
          setShowChoosePlan(true);
          localStorage.setItem("upgradePlan", false);
        }
      });
  };

  const planChosen = (plan) => {
    setNewPlan(plan);
    setShowChoosePlan(false);
    if (paymentMethod === null) {
      setShowUpdatePayment(true);
    } else {
      setShowChoosePlan(false);
      setShowConfirmPlan(true);
    }
  };

  const downloadAll = () => {
    var index = 0;
    for (const url of selectedInvoices) {
      console.log("URL", url);
      var link = document.createElement("a");
      link.href = url;
      link.download = `file${index}.pdf`;
      link.dispatchEvent(new MouseEvent("click"));
      // window.open(url);

      index++;
    }
  };

  const paymentMethodAdded = (pm) => {
    setPaymentMethod(pm);
    paymentCancel(pm);
  };

  const paymentCancel = (pm) => {
    setShowUpdatePayment(false);

    if (pm === null || pm === undefined) {
      setShowChoosePlan(true);
    } else if (newPlan !== null) {
      setShowConfirmPlan(true);
    } else if (addingCredit) {
      setShowBuyCredits(true);
    }
  };

  const closePayment = () => {
    setNewPlan(null);
    setAddingCredit(false);
    setShowUpdatePayment(false);
  };

  const confirmEditPayment = () => {
    setShowConfirmPlan(false);
    setShowBuyCredits(false);
    setShowUpdatePayment(true);
  };

  const newPlanAdded = (sub) => {
    console.log("SUBS", sub);
    setShowConfirmPlan(false);

    saveToast("Plan Updated");

    // if(!subscription)
    // {
    setPlan(newPlan);

    user.usedCredits = 0;
    user.credits = newPlan.Credits;
    user.plan = newPlan.id;
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    setUserP(user);
    updateCredits(newPlan.Credits);
    // }
    setNewPlan(null);
    setSubscription(sub);
  };

  const subscriptionCancelled = () => {
    setShowCancelPlan(false);

    // user.plan = 'free'
    // user.usedCredits = 0
    // user.credits = 50
    // localStorage.setItem("user",JSON.stringify(user))
    // setUser(user)
    // setUserP(user)

    // setSubscription(null)
    // updateCredits(50)

    // plans.forEach(planVar => {
    //     if(planVar.id === "free")
    //     {
    //         setPlan(planVar)
    //     }
    // });

    subscription.cancel_at_period_end = true;
    setSubscription(subscription);
  };

  const closeBuyCredits = () => {
    setShowBuyCredits(false);
    setAddingCredit(false);
  };

  const creditsPurchased = (amount) => {
    console.log("AMOUNT CRED", amount);

    user.credits = +user.credits + amount;
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    setUserP(user);

    updateCredits(+user.credits - user.usedCredits);
    closeBuyCredits();
  };
  return (
    <div className="fullScreen">
      <div className="container">
        <div className="savedFiltersScreen">
          <div className="sfTitles">
            <span className="display-sm semibold gray-900">{"Billing"}</span>
            <span className="text-md regular gray-600">
              {"Manage your billing and payment details."}
            </span>
          </div>

          <div className="skeleton">
            <Skeleton />
          </div>

          <div className="billingTilesRow">
            <CurrentPlanTile
              editPlan={() => setShowEditPlan(true)}
              loading={loading || loading2}
              plan={plan}
              user={user}
              addCredits={() => setShowBuyCredits(true)}
              unpausePlan={() => setShowUnpausePlan(true)}
            />
            <PaymentMethodTile
              editPayment={() => setShowUpdatePayment(true)}
              loading={loading || loading2}
              paymentMethod={paymentMethod}
              customer={customer}
            />
          </div>

          <div className="billingInvoices">
            <div className="cptTopRow centre">
              <span className="text-lg semibold gray-900">Invoices</span>
              <div className="billingDownloadOptions">
                <button className="secondaryButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.6665 14.1667L9.99984 17.5M9.99984 17.5L13.3332 14.1667M9.99984 17.5V10M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                      stroke="#344054"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-sm semibold" onClick={downloadAll}>
                    Download selected
                  </span>
                </button>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99984 10.834C10.4601 10.834 10.8332 10.4609 10.8332 10.0007C10.8332 9.54041 10.4601 9.16732 9.99984 9.16732C9.5396 9.16732 9.1665 9.54041 9.1665 10.0007C9.1665 10.4609 9.5396 10.834 9.99984 10.834Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99984 5.00065C10.4601 5.00065 10.8332 4.62755 10.8332 4.16732C10.8332 3.70708 10.4601 3.33398 9.99984 3.33398C9.5396 3.33398 9.1665 3.70708 9.1665 4.16732C9.1665 4.62755 9.5396 5.00065 9.99984 5.00065Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99984 16.6673C10.4601 16.6673 10.8332 16.2942 10.8332 15.834C10.8332 15.3737 10.4601 15.0007 9.99984 15.0007C9.5396 15.0007 9.1665 15.3737 9.1665 15.834C9.1665 16.2942 9.5396 16.6673 9.99984 16.6673Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <table className="billingTable">
              <thead>
                <tr className="text-xs medium gray-600">
                  <th className="text-xs medium gray-600">
                    <div className="row ai-centre">
                      <input
                        id="checkbox_id"
                        className="checkbox billing"
                        type="checkbox"
                        checked={
                          invoices
                            ? selectedInvoices.length === invoices.length &&
                              invoices.length !== 0
                            : false
                        }
                        onChange={(e) =>
                          checkBoxClicked("all", e.target.checked)
                        }
                      />
                      <label htmlFor="checkbox_id" className="checkboxLabel" />
                      <span>Invoice</span>
                    </div>
                  </th>
                  <th className="text-xs medium gray-600">Billing date</th>
                  <th className="text-xs medium gray-600">Status</th>
                  <th className="text-xs medium gray-600">Amount</th>
                  <th className="text-xs medium gray-600">Plan</th>
                </tr>
              </thead>

              {getTableRows()}
            </table>
          </div>
        </div>
      </div>

      {showUpdatePayment && (
        <UpdatePaymentPopUp
          close={closePayment}
          setCard={(x) => paymentMethodAdded(x)}
          cancel={paymentCancel}
        />
      )}
      {showEditPlan && (
        <EditPlanPopUp
          close={() => setShowEditPlan(false)}
          cancelPlan={openPausePlan}
          choosePlan={openChoosePlan}
          plan={plan}
          subscription={subscription}
        />
      )}
      {/* {showChoosePlan && <ChoosePlanPopUp close={()=>setShowChoosePlan(false)} planChosen={(x)=>planChosen(x)} plans={plans} planP={plan}/>} */}
      {showChoosePlan && (
        <PricingPlanSelect
          close={() => setShowChoosePlan(false)}
          purchasePlan={(x) => planChosen(x)}
          plans={plans}
          planChoice={plan}
        />
      )}
      {showCancelPlan && (
        <CancelPlanPopUp
          close={() => setShowCancelPlan(false)}
          cancel={subscriptionCancelled}
          subscription={subscription}
        />
      )}
      {showPausePlan && (
        <PausePlanPopUp
          close={() => setShowPausePlan(false)}
          cancel={subscriptionCancelled}
          subscription={subscription}
        />
      )}
      {showConfirmPlan && (
        <ConfirmPlanPopUp
          close={() => setShowConfirmPlan(false)}
          plan={newPlan}
          dismissNewPlan={() => setNewPlan(null)}
          paymentMethod={paymentMethod}
          editPayment={confirmEditPayment}
          currentSubscription={subscription}
          complete={newPlanAdded}
        />
      )}

      {showBuyCredits && (
        <BuyCreditsPopUpNew
          user={user}
          paymentMethod={paymentMethod}
          editPayment={confirmEditPayment}
          close={closeBuyCredits}
          complete={creditsPurchased}
        />
      )}
      {showUnpausePlan && (
        <UnpausePlanPopUp
          close={() => setShowUnpausePlan(false)}
          cancel={() => setShowUnpausePlan(false)}
          subscription={subscription}
          pausedUntil={user.planPaused}
        />
      )}
      {/* {loading && <LoadingPopUp />} */}
    </div>
  );
}
