
import React, {useState } from 'react';
import axios from "axios";
import { CSVLink } from 'react-csv';

export default function AIDataScreen() {


    const baseURL2 = process.env.REACT_APP_BASEURL2;
    // const localURL2 = process.env.REACT_APP_LOCALURL2;

    const [inputArray, setInputArray] = useState([]);
    const [outputJSON, setOutputJSON] = useState([]);


    const [loading, setLoading] = useState(false);



         
    const inputFileChange = (e) => {

        const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = e => {

            let lines = CSVToArray(e.target.result,",") //e.target.result.split(/\r?\n/);
            console.log("LINES",lines)

                    var array = []


                    var index = 0

                    lines.forEach(function (line) {
                        
                    
                        if(index>0)
                        {
                            let id = line[0]
                            let name = line[1] || ""
                            let postCode = line[2] || ""
                            let website = line[3] || ""
                        

                            if(id!=="")
                            {
                                array.push({id:id,name:name,postCode:postCode,website:website})
                            }

                        }
                        
                            

                        index++;
                    }); 

                    console.log("input ARRAY",array)
                    setInputArray(array)



            }
            reader.readAsText(file);
    } 


    const uploadInitialArray = () => {

        setLoading(true)

        axios.post(baseURL2+'getCompaniesAIData',{
            
            input:inputArray
    
        }).then((response) => {
            
            setLoading(false)

            let resVar = response.data
            console.log("Response", resVar)
            setOutputJSON(resVar)
            
        })

    }


 function CSVToArray( strData, strDelimiter ){
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
        );


    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;


    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    // eslint-disable-next-line
    while (arrMatches = objPattern.exec( strData )){

        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[ 1 ];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
            ){

            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push( [] );

        }

        var strMatchedValue;

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[ 2 ]){

            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
                );

        } else {

            // We found a non-quoted value.
            strMatchedValue = arrMatches[ 3 ];

        }


        // Now that we have our value string, let's add
        // it to the data array.
        arrData[ arrData.length - 1 ].push( strMatchedValue );
    }

    // Return the parsed data.
    return( arrData );
}



    return(
        <div className="homeScreen">

            <span className='text-lg semibold marginTop200'>Original File</span>
            <input className='fileInput' type="file" onChange={inputFileChange}/>
            {(inputArray.length>0 && !loading)&&  <button className='secondaryButton' onClick={()=>uploadInitialArray()}>Get extra data</button>}
    
            {loading && <span>Getting Data....</span>}


            {(outputJSON.length>0 && !loading) && <CSVLink data={outputJSON} ><button className='testBtn'>Download</button></CSVLink>}


        </div>
    )

}