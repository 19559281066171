import { auth } from '../..';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";

export default function CancelPlanPopUp({close,cancel,subscription}) {

   
    const [loading, setLoading] = useState(false)

    console.log("SUBSC",subscription)

    const baseURL2 = process.env.REACT_APP_BASEURL2;
    // const localURL2 = process.env.REACT_APP_LOCALURL2;

        
    useEffect(() => {
        
    },[])


    const cancelSubscription = () => {

        setLoading(true)

        axios.post(baseURL2+'cancelSubscription',{
            uid:auth.currentUser.uid,
            subscriptionID:subscription.id
            
        }).then( (response) => {
            setLoading(false)
            cancel()
        });
    }
    


    

    return(

        <div className='tint'>
            <div className='popUp cancelPlan'>
                <div className='row jc-end width100'>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close'/>
                </div>

                <div className='sfpuMain inner3'>
                    <span className='text-lg semibold gray-900'>Cancel subscription</span>
                    <span className='text-sm regular gray-600'>Are you sure you want to cancel your subscription?</span>
                </div>

                {loading && <div className='loader'>
                    <lottie-player src="https://lottie.host/998ea8e9-6e3b-43b9-8fe1-0bd824113069/xWUkOHakRC.json" background="transparent" speed="1" style={{transform: 'scale(0.7)'}} loop autoplay></lottie-player>
                    </div>
                }

                {!loading && <div className='row width100 jc-between gap12'>
                    <button className='secondaryButton width40' onClick={close} disabled={loading}>Keep plan</button>
                    <div className="tooltip">
                        <button className='primaryButton width100' onClick={cancelSubscription} disabled={loading}>Cancel Subscription</button>
                        {/* <span className="tooltiptext button text-sm regular">Coming soon</span> */}
                    </div>
                </div>}

                
                
            </div>            
        </div>

    )

}